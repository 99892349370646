<template>
  <div>
    <v-form>
      <p class="mb-3">
        Examine the
        <chemical-latex content="^{13}C" />
        and
        <chemical-latex content="^1H" />
        spectra of compound E. All the proton signals are above
        <stemble-latex content="$5\,\text{ppm}.$" />
      </p>
      <!-- What UN/DBE? -->
      <p class="mb-2">
        What fragment would account for that and the UN/DBE that you calculated earlier?
      </p>
      <v-radio-group v-model="inputs.input1" class="ml-6 mb-5" :disabled="!allowEditing">
        <v-radio
          v-for="option in radioOptionsShuffled"
          :key="option.text"
          :value="option.value"
          class="my-1"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        The singlet at
        <stemble-latex content="$11\,\text{ppm}$" />
        is characteristic of which functional group? (Hint: two words, spelling counts!)
      </p>
      <p>
        functional group:
        <v-text-field
          v-model="inputs.input2"
          class="mb-5"
          style="display: inline-block; width: 15em"
        />
      </p>

      <!-- TODO: Put a "draw" here -->

      <p class="mb-2">
        What is the molecular formula of the molecule you just drew? (Use the given format: CxHyOz,
        where x, y and z are numbers.)
      </p>
      <p>
        molecular formula:
        <v-text-field
          v-model="inputs.input3"
          class="mb-5"
          style="display: inline-block; width: 15em"
        />
      </p>

      <p class="mb-2">
        The difference between the formula you just wrote out and the molecular formula of compound
        E is one atom. That atom is part of the functional group that also accounts for the singlet
        at
        <stemble-latex content="$5.4\,\text{ppm}$" />
        in the
        <chemical-latex content="^1H" />
        NMR spectrum. What is the name of the functional group?
      </p>
      <p>
        name of the functional group:
        <v-text-field
          v-model="inputs.input4"
          class="mb-5"
          style="display: inline-block; width: 15em"
        />
      </p>

      <p class="mb-2">
        How will you combine the fragment you drew with this functional group? To get to the correct
        structure of compound E you will have to carefully consider the splitting patterns in the
        <chemical-latex content="^1H" />
        NMR spectrum.
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '../inputs/CalculationInput.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LBA2M5Q2',
  components: {ChemicalLatex, StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      radioOptions: [
        {text: 'cyclohexyl ring', value: 'cyclo'},
        {text: 'benzene ring', value: 'benzene'},
        {text: '$tert-$butyl group', value: 'tert'},
        {text: 'alkene', value: 'alkene'},
        {text: 'alkyne', value: 'alkyne'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    radioOptionsShuffled() {
      return seededShuffle(this.radioOptions, this.seed);
    },
  },
};
</script>
